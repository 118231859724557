@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hides scrollbar while allowing scrolling */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
